.daily-count {
  font-size: 18px;
  line-height: 40px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  @media (max-width: 480px) {
    flex-direction: column;
    font-size: 15px;
  }
  .icons-container {
    img {
      height: 40px;
      width: auto;
      border: 2px solid #d0d0d0;
      border-radius: 50%;
      &:last-of-type {
        position: relative;
        left: -15px;
      }
    }
  }
}
