.who-we-are-container {
  padding: 80px 0;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 419 522' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M419 261L-1 0.326355L-1 521.674L419 261Z' fill='%23FF4446'/%3E%3C/svg%3E");
  background-size: 419px 522px;
  background-position: 0% calc(50% + 100px);
  background-repeat: no-repeat;
  position: relative;
  @media (max-width: 1300px) {
    background-position: -200px calc(50% + 100px);
  }
  @media (max-width: 480px) {
    background-position: -200px 300px;
  }
  .container {
    justify-content: space-between;
    align-items: flex-start;
    gap: 80px;
    max-width: 1198px;
    margin: 0 auto;
    @media (max-width: 480px) {
      flex-direction: column;
    }
    .headline-container {
      text-align: center;
      flex: 1;
      p {
        padding-top: 20px;
        font-size: 20px;
        line-height: 32px;
        font-weight: 400;
        opacity: 0.9;
        @media (max-width: 480px) {
          font-size: 16px;
        }
      }
    }
  }
  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 80px;
    margin-top: 40px;
    @media (max-width: 480px) {
      flex-direction: column;
    }
    .image-container {
      flex: 1;
      svg,
      img {
        width: 100%;
        height: auto;
      }
    }
    .panels-container {
      flex: 1;
      // @media(max-width: 480px){
      //   // flex-direction: column;
      //   // margin: 40px 0;
      //   // text-align: center;
      // }
      .panel {
        .headline {
          font-size: 28px;
          line-height: 33.89px;
          font-weight: 700;
          color: #ff4466;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          svg {
            height: 64px;
            width: auto;
          }
        }
        p {
          font-size: 18px;
          font-weight: 500;
          line-height: 30px;
          margin: 20px 0 40px;
        }
      }
    }
  }
}
