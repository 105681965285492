.how-it-works-container {
  background-color: #f9f9f9;
  padding: 80px 0;
  text-align: center;
  position: relative;
  @media (max-width: 480px) {
    padding: 40px 0;
  }
  .panels-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1136px;
    margin: 60px auto;
    @media (max-width: 480px) {
      flex-direction: column;
      gap: 15px;
      margin: 30px auto;
    }
    // gap: 100px;
    .panel {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      flex: 1 1 10%;
      .step {
        font-weight: 800;
        font-size: 64px;
        text-align: center;
        color: #000;
        opacity: 0.45;
        line-height: 60px;
        @media (max-width: 480px) {
          font-size: 32px;
          line-height: 38.73px;
        }
      }
      .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 144px;
        width: 144px;
        background: #fff;
        box-shadow: 0px 3.246488094329834px 24.34865951538086px 0px rgba(0, 0, 0, 0.15);
        svg {
          height: 84px;
          width: auto;
        }
      }
      label {
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        margin: 20px auto 0;
        width: 220px;
      }
    }
    .divider-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      // width: 200px;
      flex: 3 1 35%;
      div {
        border-bottom: 2px dashed #ff4446;
        width: 100%;
        height: 1px;
      }
    }
  }
  // .panels-container {
  //   display: flex;
  //   gap: 70px;
  //   align-items: center;
  //   justify-content: center;
  //   // @media(max-width: 480px){
  //   //   flex-direction: column;
  //   // }
  //   .panel-container {
  //     flex: 1;
  //     text-align: left;
  //     @media(max-width: 480px){
  //       text-align: center;
  //     }
  //     label {
  //       font-family: "PT Serif", serif;
  //       font-size: 22px;
  //       font-weight: 500;
  //       line-height: 1.5;
  //     }
  //   }
  // }
}
