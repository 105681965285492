.hero-container {
  position: relative;
  .hero {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    max-height: calc(100vh - 102px);
    max-width: 100vw;
  }
  .container {
    text-align: center;
    min-height: calc(100vh - 102px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      max-width: 576px;
      padding: 10px;
      margin: 30px 0 0;
    }
  }
}
