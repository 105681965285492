.category-links-wrapper {
  background: #fff;
  border: 1px solid #efefef;
  width: 100%;
  max-width: 865px;
  margin-top: 50px;
  @media (max-width: 480px) {
    background: transparent;
  }
  .category-links-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 480px) {
      flex-wrap: wrap;
      gap: 20px;
      margin-bottom: 40px;
      //   margin: 0 auto 20px;
      //   align-items: flex-start;
      //   justify-content: flex-start;
    }
    .category-link {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 36px 0;
      @media (max-width: 480px) {
        flex: 0 1 calc(50% - 10px);
        box-shadow: 0 4px 30px 0px rgba(0, 0, 0, 0.15);
        background: #fff;
      }
      &:hover,
      &:active,
      &:focus {
        box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
      }
      .icon-container {
        flex: 1;
        flex-basis: 100%;
        text-align: center;
        .icon-wrapper {
          height: 72px;
          width: 72px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.25s ease;
          svg {
            height: 72px;
            width: auto;
          }
        }
      }
      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        white-space: nowrap;
        text-align: center;
      }
    }
  }
}
