.hero-category-picker-container {
  width: 100%;
  max-width: 865px;
  margin: 0 auto 40px;
  fieldset {
    border: none;
    padding: 0;
    text-align: left;
    width: 100%;
    legend {
      /* visually hidden, but available to screen readers */
      opacity: 0;
      height: 0;
    }
    :focus-visible {
      outline: 2px solid #ff4446;
    }
    @media (max-width: 480px) {
      max-width: none;
    }
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .input-container {
        flex: 2;
        @media (max-width: 320px) {
          width: 100%;
        }
        &.hasValue {
          input,
          textarea,
          select {
            font-weight: 400;
          }
        }
        &.error {
          input,
          textarea,
          select {
            border-color: #b73e18;
            color: #b73e18;
          }
          .error_msg {
            display: block;
            text-align: center;
          }
          select {
            appearance: none;
          }
        }
      }
      .custom-dropdown {
        font-size: 18px;
        line-height: 72px;
        font-weight: 400;
        cursor: pointer;
        position: relative;
        background: #fff;
        .selected-value {
          border: 1px solid #ff4446;
          height: 72px;
          padding-left: 20px;
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            right: 10px;
            width: 19px;
            height: 12px;
            background-image: url("data:image/svg+xml,%3Csvg width='19' height='12' viewBox='0 0 19 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.5 11.5L18.1603 0.25H0.839746L9.5 11.5Z' fill='black'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            transform: translateY(-50%);
            transition: transform 0.3s ease;
          }
        }
        &.open {
          .selected-value {
            &:before {
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }
        .dropdown-list {
          position: absolute;
          background: #fff;
          top: 73px;
          left: 0;
          right: 0;
          box-shadow: 0 4px 30px 0px rgba(0, 0, 0, 0.15);
          z-index: 2;
          max-height: 400px;
          overflow: auto;
          list-style: none;
          padding: 0 20px;
          margin: 0;
          line-height: 50px;
          li {
            border-bottom: 1px solid rgba(208, 208, 208, 0.4);
            &:hover,
            &.focus,
            &.active,
            &.highlighted {
              color: #ff4446;
            }
          }
        }
      }
    }
    .error_msg {
      color: #b73e18;
      font-weight: 500;
      padding: 3px 0;
      font-size: 15px;
    }
    .search {
      padding: 0 30px;
      // margin-left: 12px;
      svg {
        height: 25px;
      }
    }
  }
}
