.right-arrow-decal {
  position: absolute;
  bottom: -78px;
  right: 0;
  height: 156px;
  width: 68px;
  z-index: 100;
  @media (max-width: 480px) {
    display: none;
  }
}
